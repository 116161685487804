<template>
	<div id="landList">
        <!-- <com-plugin-detail></com-plugin-detail>    -->
		<page-land-list :is-shop="0"></page-land-list>
	</div>
</template>
<script>
import pageLandList from '@/components/layout/land/page-land-list.vue'
// import comPluginDetail from '@/components/layout/plugin/com-plugin-detail.vue'

import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
	export default{
		name:"LandList",
		components:{
			pageLandList,
            // comPluginDetail
		},
		setup(){
			onBeforeRouteLeave((to,from,next)=>{
				let type = to.path == '/land/landEdit' ? 1 : 2
				store.dispatch("setKeepPage",{name:from.name,type})
		    	next();
			})
		}
	}
</script>

<style>
</style>
